import { Effect } from "babylonjs/Materials/effect";
import "babylonjs/Shaders/ShadersInclude/bonesDeclaration";
import "babylonjs/Shaders/ShadersInclude/instancesDeclaration";
import "babylonjs/Shaders/ShadersInclude/clipPlaneVertexDeclaration";
import "babylonjs/Shaders/ShadersInclude/fogVertexDeclaration";
import "babylonjs/Shaders/ShadersInclude/lightFragmentDeclaration";
import "babylonjs/Shaders/ShadersInclude/lightUboDeclaration";
import "babylonjs/Shaders/ShadersInclude/instancesVertex";
import "babylonjs/Shaders/ShadersInclude/bonesVertex";
import "babylonjs/Shaders/ShadersInclude/clipPlaneVertex";
import "babylonjs/Shaders/ShadersInclude/fogVertex";
import "babylonjs/Shaders/ShadersInclude/shadowsVertex";

let name = 'gradientVertexShader';
let shader = `precision highp float;

attribute vec3 position;
#ifdef NORMAL
attribute vec3 normal;
#endif
#ifdef UV1
attribute vec2 uv;
#endif
#ifdef UV2
attribute vec2 uv2;
#endif
#ifdef VERTEXCOLOR
attribute vec4 color;
#endif
#include<bonesDeclaration>

#include<instancesDeclaration>
uniform mat4 view;
uniform mat4 viewProjection;
#ifdef POINTSIZE
uniform float pointSize;
#endif

varying vec3 vPositionW;
varying vec3 vPosition;
#ifdef NORMAL
varying vec3 vNormalW;
#endif
#ifdef VERTEXCOLOR
varying vec4 vColor;
#endif
#include<clipPlaneVertexDeclaration>
#include<fogVertexDeclaration>
#include<__decl__lightFragment>[0..maxSimultaneousLights]
void main(void) {
#include<instancesVertex>
#include<bonesVertex>
vec4 worldPos=finalWorld*vec4(position,1.0);
gl_Position=viewProjection*worldPos;
vPositionW=vec3(worldPos);
vPosition=position;
#ifdef NORMAL
vNormalW=normalize(vec3(finalWorld*vec4(normal,0.0)));
#endif

#ifndef UV1
vec2 uv=vec2(0.,0.);
#endif
#ifndef UV2
vec2 uv2=vec2(0.,0.);
#endif

#include<clipPlaneVertex>

#include<fogVertex>
#include<shadowsVertex>[0..maxSimultaneousLights]

#ifdef VERTEXCOLOR
vColor=color;
#endif

#ifdef POINTSIZE
gl_PointSize=pointSize;
#endif
}
`;

Effect.ShadersStore[name] = shader;
/** @hidden */
export var gradientVertexShader = { name, shader };
